import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column } from 'simple-flexbox';
import HashLoader from "react-spinners/HashLoader";

const useStyles = createUseStyles({
    '@keyframes loadingSpin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' }
    },
    container: {
        backgroundColor: "#fff",
        height: '100%',
        minHeight: ({ fullScreen }) => (fullScreen ? '100vh' : '100%'),
        width: ({ fullScreen }) => (fullScreen ? '100vw' : '100%'),
        position: ({ fullScreen }) => (fullScreen ? 'fixed' : 'relative'),
        top: 0,
        left: 0,
        zIndex: ({ zIndex }) => zIndex
    },
    loadingSpan: {
        color: 'white',
        marginTop: 10,
        fontSize: 18
    }
});

function Loading({
    backgroundColor,
    fullScreen,
    height,
    noTransparency,
    width,
    zIndex
}) {
    const theme = useTheme();
    const classes = useStyles({ theme, fullScreen, noTransparency, backgroundColor, zIndex });
    return (
        <div style={{ position: 'relative', height, width }}>
            <Column className={classes.container} horizontal='center' vertical='center'>
                <HashLoader 
                    color="#5d17eb" 
                    loading={true} 
                    size={50} 
                />
            </Column>
        </div>
    );
}

Loading.defaultProps = {
    fullScreen: true,
    zIndex: 10
};

export default Loading;