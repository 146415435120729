import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import Theme from './resources/theme';
import Routes from "./routes/routes.js";
import firebase from "./config/firebase";


const ChangeTitle = () =>{

  const urlAtual = window.location.pathname
  firebase
      .database()
      .ref()
      .child('users')
      .orderByChild('urlSite')
      .equalTo(`${urlAtual}`)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          snapshot.forEach(function(item){
              document.title = item.val().nomeComp
              document.getElementById('apple-touch-icon').href = item.val().perfilDeCampanha.image01Perfil
          })
        }
  });

  
}

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <Router>
      <Routes/>
    </Router>
  </ThemeProvider>,
  document.getElementById("root"),
  ChangeTitle
);
