const color = {
    azul: '#4285F4'
};

const typography = {
    cardTitle: {
        fontSize: 19,
    },
};

const theme = {
    color,
    typography,
};

export default theme;