import React, {lazy, Suspense, useState} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from "../components/loading/loading";
import firebase from "../config/firebase";

const home = lazy(() => import('./home/home'));
const clients = lazy(() => import('./clients/clients'));
const error = lazy(() => import('./error/error'));
const offTemp = lazy(() => import('./offTemp/offTemp'));

const postsBlog = lazy(() => import('./posts/blog/PostsBlog'));
const compBlog = lazy(() => import('./posts/comps/compBlog/compBlog'));
const postsProjetos = lazy(() => import('./posts/projects/PostsProjetos'));


export default function App() {
  const [fullClient, setFullClient] = useState(false)
  const [postsClientBlog, setPostsClientBlog] = useState(false)
  const [compPostsClientBlog, setCompPostsClientBlog] = useState(false)
  const [postsClientVideos, setPostsClientVideos] = useState(false)
  const [forZeroFor, setForZeroFor] = useState(false)

  const urlAtual = window.location.pathname;
  const editUrl = urlAtual.split('/').join(' ');
  const finalValue = editUrl.split(' ')[1];

  firebase.database().ref().child('users').orderByChild('urlSite').equalTo(`/${finalValue}`).once('value', function (snapshot) {
    if (snapshot.exists() && urlAtual.indexOf('postsBlog') === -1 && urlAtual.indexOf('postsProjetos') === -1 && urlAtual.indexOf('postsBgCompartilhar') === -1) {
      setFullClient(true);
    }else if(snapshot.exists() && urlAtual.indexOf('postsBlog') > -1){
      setPostsClientBlog(true);
    }else if(snapshot.exists() && urlAtual.indexOf('postsProjetos') > -1){
      setPostsClientVideos(true);
    }else if(snapshot.exists() && urlAtual.indexOf('postsBgCompartilhar') > -1){
      setCompPostsClientBlog(true);
    }else{
      setForZeroFor(true);
    }
  });

  return (
    <Router>
      <Suspense fallback={<Loading/>}>
        <Switch>
          <Route exact path="/" component={home}/>
          <Route exact path="/404" component={error}/>
          <Route exact path="/offline-temporariamente" component={offTemp}/>
          {forZeroFor &&(<Route component={error}/>)}
          {fullClient &&(<Route component={clients}/>)}
          {postsClientBlog &&(<Route component={postsBlog}/>)}
          {postsClientVideos &&(<Route component={postsProjetos}/>)}
          {compPostsClientBlog &&(<Route component={compBlog}/>)}
        </Switch>
      </Suspense>
    </Router>
  );
}